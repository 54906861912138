.info-popup-content {
  margin: auto;
  background: white;
  min-width: 250px;
  padding: 10px;
  border-radius: 10px;
  border-top-right-radius: 0;
}
.info-popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].info-popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px;
}

.info-popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].info-popup-overlay {
  background: transparent;
}
